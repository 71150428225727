export const Official=({designation, name, description})=>{
    return(
      <div className='bg-gray-900 suse w-9/12 m-10 p-2 rounded-3xl text-center'>
        <h1 className='text-3xl my-2'>{designation}</h1>
        <h2 className='text-2xl my-1'>{name}</h2>
        <h2 className='text-2xl'>{description}</h2>
      </div>
    )}

export const Partner=({name, description})=>{
    return(
      <div className='bg-gray-900  w-9/12 m-10 p-2 suse rounded-3xl text-center'>
        <h1 className='text-3xl my-2'>{name}</h1>
        <h2 className='text-2xl'>{description}</h2>
      </div>
    )}

export const CampusCard=({name,state})=>{
  return(
    <div className="bg-gray-900 suse w-[300px] h-[400px] items-center justify-center p-4 m-4 text-center rounded-2xl">
      <h1>{name}</h1>
      <h1>{state}</h1>
    </div>
  )
}

