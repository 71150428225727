import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';
import Error from './Error';
import Donate from './Donate';
import Contact from './Contact';
import Campuses from './Campuses';
import { Background } from './Components/Background';
import Partners from './Partners';
import About from './About';
import Blog from './Blog';


const root = ReactDOM.createRoot(document.getElementById('root'));

const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Background />
      },
      {
        path: '/donate',
        element: <Donate />
      },
      {
        path: '/contact',
        element: <Contact />
      },
      {
        path: '/campuses',
        element: <Campuses />
      },
      {
        path: '/about',
        element: <About />
      },
      {
        path: '/partners',
        element: <Partners />
      },
      {
        path:'/blog',
        element : <Blog />
      }
    ],
    errorElement : <Error/>
  },
])

root.render(
  <React.StrictMode>
    <RouterProvider router={appRouter} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
