import React from 'react';
const Donate = () => {
  return (
    <div className='flex flex-col h-screen'>
      <a href="https://hcb.hackclub.com/donations/start/mapconnect" target="_blank" rel="noopener noreferrer"></a>
    </div>
  );
};

export default Donate;
