import { Outlet } from 'react-router-dom';
import './App.css';
import { NavigationBar } from './Components/NavigationBar';

function App() {
  return (
    <div className="justify-center bg-black">
      <NavigationBar />
      <Outlet />
    </div>
  );
}


export default App;
