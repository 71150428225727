import React from 'react'
import Team from './Team'
import Partners from './Partners'
import Contact from './Contact'
import Footer from './lib/Footer'

const About = () => {
  return (
    <div className="flex flex-col space-y-4 text-sm">
        <Team />
        <Partners />
        <Contact />
        <Footer />
    </div>
  )
}

export default About