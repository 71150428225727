"use client";
import React from "react";
import { WavyBackground } from "./ui/wavy-background";
import { MapconnectButton } from "./ui/MapconnectButton";

export function Background() {
  return (
    (<WavyBackground className="max-w-4xl mx-auto pb-40">
      <h1 className="text-2xl mt-40 md:text-4xl lg:text-7xl suse text-white font-bold text-center inter-var">Website under active development</h1>
      <h1 className="text-2xl md:text-4xl lg:text-5xl suse p-4 text-white text-center font-bold inter-var">MapConnect</h1>
      <MapconnectButton />
    </WavyBackground>)
  );
}
