import React from 'react'
import { Partner } from './lib/Cards'

const Partners = () => {
  return (
    <div >
      <h1 className='text-5xl text-center'>Partners</h1>
      <div className='grid grid-cols-3 gap-4'>
        <Partner name='name' description='description' />
        <Partner name='name' description='description' />
        <Partner name='name' description='description' />
        </div>
    </div>
  )
}

export default Partners