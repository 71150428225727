import React from 'react'
import { BlogCard } from './Components/ui/BlogCard'

const Blog = () => {
  return (
    <div className='mt-[140px] text-5xl text-center'>
        Blog
        <BlogCard />
    </div>
  )
}

export default Blog