import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
const Contact = () => {

  const [name,setName] = useState()
  const [email,setEmail] = useState()
  const [msg,setMsg] = useState()

  
  const handleSubmit =(e)=>{
    e.preventDefault();

    const servicId = process.env.REACT_APP_EMAILJS_SERVICE_ID
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY

    const template_params = {
        from_name : name,
        from_email : email,
        to_name : "mapConnect",
        message : msg
    }
    emailjs.send(servicId, templateId, template_params, publicKey)
    .then((response)=>{
      console.log("Email sent successfully",response)
      setEmail("")
      setName("")
      setMsg("")
    })
    .catch((error)=>{
      console.log("error sending email",error)
    })

  }

  
  return (
    <>
      <div className='mt-[120px] suse text-5xl text-center'>Contact Us here</div>
      <section class=" dark:bg-black-900">
        <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <form action="#" onSubmit={handleSubmit} className="space-y-8">
                <div>
                    <label for="email" class="block mb-2 text-lg font-medium text-gray-900 dark:text-gray-300">Email </label>
                    <input type="email" value={email} onChange={(e)=>{
                      setEmail(e.target.value)
                      console.log(email)
                    }} id="email" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="name@gmail.com" required />
                </div>
                <div>
                    <label for="subject" class="block mb-2 text-lg font-medium text-gray-900 dark:text-gray-300">Name</label>
                    <input type="text" value={name} onChange={(e)=>{
                      setName(e.target.value)
                      console.log(name)
                    }} id="subject" class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Please enter your name" required />
                </div>
                <div class="sm:col-span-2">
                    <label for="message" class="block mb-2 text-lg font-medium text-gray-900 dark:text-gray-400">Your message</label>
                    <textarea id="message" value={msg} onChange={(e)=>{
                      setMsg(e.target.value)
                      console.log(msg)
                    }} rows="6" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Let us know how we can help you"></textarea>
                </div>
                <button type="submit" className="bg-blue-200 rounded-lg text-black p-2 px-4">Send message</button>
            </form>
        </div>
      </section>
    </>

  )
}

export default Contact