"use client";
import React, { useState } from "react";
import { Menu, MenuItem, ProductItem } from "./ui/navbar-menu";
import { cn } from "../lib/utils";
import { Link } from "react-router-dom";

export function NavigationBar() {
  return (
    (<div className="relative w-full flex items-center justify-center">
      <Navbar className="top-2" />
    </div>)
  );
}

function Navbar({
  className
}) {
  const [active, setActive] = useState(null);
  return (
    (<div
      className={cn("fixed inset-x-0 max-w-2xl mx-auto z-50", className)}>
      <Menu setActive={setActive}>
        <Link to={"/"}>
          <MenuItem setActive={setActive} active={active} item="Home">
          </MenuItem>
        </Link>
        <Link to={"/about"}>
          <MenuItem setActive={setActive} active={active} item="About Us">
          </MenuItem>
        </Link>   
      <MenuItem setActive={setActive} active={active} item="Campuses">
        <div className="text-sm grid grid-cols-2 gap-10 p-4">
          <a href="https://milpitas.mapconnect.org" target="_blank" rel="noopener noreferrer">
            <ProductItem
              title="Milpitas High School"
              href={"https://milpitas.mapconnect.org"}
              src="https://assets.aceternity.com/demos/algochurn.webp"
              description="Maps"
            />
          </a>
          <h1 className="text-center align-middle font-bold text-2xl alignitems-center mt-8 cursor-pointer">
            <a href="/campuses">All Campuses</a>
          </h1>
        </div>
      </MenuItem>
        <Link to={"/blog"}>
          <MenuItem setActive={setActive} active={active} item="Blog">
          </MenuItem>
        </Link>



      <a href="https://hcb.hackclub.com/donations/start/mapconnect" target="_blank" rel="noopener noreferrer">
        <MenuItem setActive={setActive} active={active} item="Donate">
        </MenuItem>
      </a> 
      </Menu>
    </div>)
  );
}
