import React from 'react'
import { Official } from './lib/Cards'

const d= "description"


const Team = () => {
  return (
    <div className='mt-[140px] bg-black '>
      <h1 className='text-5xl text-center'>Team</h1>
      <div className='grid grid-cols-3 gap-4'>
        <Official designation='CEO' name='name' description={d} />
        <Official designation='CTO' name='name' description='description' />
        <Official designation='CFO' name='name' description='description' />
        <Official designation='Software Engineer' name='name' description='description' />
        <Official designation='Software Engineer' name='name' description='description' />
        <Official designation='Software Engineer' name='name' description='description' />
      </div>
      
    </div>
  )
}

export default Team