import React from 'react'
import { CampusCard } from './lib/Cards'
import Footer from './lib/Footer'

const campusinfo = [
  {name: 'Milpitas High School', state:'California'},
  {name: 'campus2', state:'state2'},
  {name: 'campus3', state:'state3'},
  {name: 'campus4', state:'state4'},
  {name: 'campus5', state:'state5'},
  {name: 'campus6', state:'state6'},
  {name: 'campus7', state:'state7'},
]
const Campuses = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <h1 className='mt-[120px] text-5xl text-center'>Campuses</h1>
      <div className='flex flex-wrap grow m-4 p-4 px-16'>
        {campusinfo.map((campus) => {
          return (
            <CampusCard key={campus.name} name={campus.name} state={campus.state} />
          );
        })}
      </div>
      <Footer />
    </div>
  );
};


export default Campuses